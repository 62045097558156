import { render, staticRenderFns } from "./NotificationCard.vue?vue&type=template&id=24028cac&scoped=true&lang=pug"
import script from "./NotificationCard.vue?vue&type=script&lang=js"
export * from "./NotificationCard.vue?vue&type=script&lang=js"
import style0 from "./NotificationCard.vue?vue&type=style&index=0&id=24028cac&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24028cac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyNotifications: require('/opt/build/repo/components/EmptyState/EmptyNotifications.vue').default,OneNotification: require('/opt/build/repo/components/DataDisplay/OneNotification.vue').default,GenericList: require('/opt/build/repo/components/DataDisplay/GenericList.vue').default,EmptyMediaItem: require('/opt/build/repo/components/EmptyState/EmptyMediaItem.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
