
export default {
    props: {
        notifications: {
            type: Array,
            default: () => [],
        },
        limit: {
            type: Number,
            default: 3,
        },
        loading: {
            type: Boolean,
            default: () => false,
        }
    }
}
